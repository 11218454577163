import moment from 'moment';
import { useQuery } from 'react-query';

async function fetchTimetable(startDate, endDate, userId) {
  const query = new URLSearchParams();
  if (userId) query.set('userId', userId);
  if (startDate) query.set('startDate', moment(startDate).format('YYYY-MM-DD'));
  if (endDate) query.set('endDate', moment(endDate).format('YYYY-MM-DD'));
  const response = await fetch(`/api/timetable?${query}`);
  const responseBody = await response.json();
  if (!response.ok) throw new Error(responseBody.message);
  return responseBody;
}

export default function useTimetable(state) {
  const { startDate, endDate, isFuture, user } = state;
  const userId = user?.id;
  return useQuery(
    ['timetable', { startDate, endDate, userId }],
    () => fetchTimetable(startDate, endDate, userId),
    {
      enabled:
        (user === undefined || !!user) &&
        (isFuture === 'false' || !!(startDate && endDate)),
    }
  );
}
