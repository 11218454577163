import { useQuery } from 'react-query';

async function fetchUsers() {
  const response = await fetch('/api/users');
  const responseBody = await response.json();
  if (!response.ok) throw new Error(responseBody.message);
  return responseBody;
}

export default function useUsers() {
  return useQuery('users', fetchUsers, {
    refetchOnWindowFocus: false,
  });
}
